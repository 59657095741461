<template>
  <v-card :class="[`border-${type}`, `bg-backgroundSecondary`,]">
    <v-card-text>
      <v-row>
        <v-col cols="auto">
          <v-icon :color="props.type" :icon="mdiLightbulbOn10"/>
        </v-col>
        <v-col class="pl-0">
          <v-row v-if="$slots.default">
            <v-col>
              <slot/>
            </v-col>
          </v-row>
          <v-row v-if="$slots.actions">
            <v-col>
              <slot name="actions"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts" setup>
import {mdiLightbulbOn10} from '@mdi/js';
import {useTheme} from 'vuetify';

interface Props {
  type: 'info' | 'warning' | 'error'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'warning',
});

const theme = useTheme();
// const typeColorHex = computed<string>(() => theme.current.value.colors[props.type]);

</script>
<style scoped lang="scss">

.bg-warning-low-emphasis {
  /*TODO: v-bind(typeColorHex) niestety zwraca hexa co zabrania nam operować na rgba :( */
  background-color: rgba(var(--v-theme-warning), var(--v-low-emphasis-opacity));
}
</style>
